import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomMenuItem } from '../models/menu-item.model';

@Injectable({
    providedIn: 'root',
})
/**
 * menu data service
 */
export class MenuDataService {

    public toggleMenuBar: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    getMenuList(): CustomMenuItem[] {
        return [
            {
                Label: 'Home', Icon: 'fa-home', RouterLink: '/dashboard',  IsChildVisible: false
            },
            {
                Label: 'Reporting', Icon: 'fa-users', RouterLink: '/reporting',  IsChildVisible: false
            },
        
            {
                Label: 'ContactUs', Icon: 'fa-envelope', RouterLink: '/main/contactus', Childs: [], IsChildVisible: false
            }          
        ];
    }
}