import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { fetchAuthSession } from 'aws-amplify/auth';
/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(fetchAuthSession()).pipe(switchMap(ses => {

            if (!request.headers.has('Content-Type') && request.url.indexOf('upload-script') < 0) {
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            }
            const token = ses.tokens?.idToken?.toString();
            let clonedRequest = request.clone({ headers: request.headers.append('Authorization', `Bearer ${token}`) });

            clonedRequest = clonedRequest.clone({ headers: clonedRequest.headers.set('Accept', 'application/json') });
            if (request.url.indexOf('s3.us-east-2.') < 0) {
                // Pass the cloned request instead of the original request to the next handle
                return next.handle(clonedRequest).pipe(
                    map((event: HttpEvent<any>) => {
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        let data = {};
                        console.log(error);
                        if (error.status === 401) {

                        }
                        return throwError(error);
                    }));
            }
            else {
                return next.handle(request).pipe(
                    map((event: HttpEvent<any>) => {
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        let data = {};
                        console.log(error);
                        if (error.status === 401) {

                        }
                        return throwError(error);
                    }));
            }
        }))


    }



}

