<p-sidebar [visible]="visible" position="left" [showCloseIcon]="false" styleClass="np-menubar" [modal]="false">
    <div class="logo center">
        <img src="../../../assets/Aurora Health Logo_final.webp" class="logo-large">
        <!-- <img src="assets/images/logo-small.png" class="logo-small"> -->
    </div>
    <div class="toggle-menu-button-menu right">
        <a class="icon close-small" (click)="toggleMenu()"><i class="fa-solid fa-xmark bars"></i></a>
    </div>
    <br/>
    <ul class="np-menu">
        <ng-template #recursiveList let-list>
            <li *ngFor="let menu of list">
                <a (click)="onMenuClick(menu)" [ngClass]="{'active': selectedItem == menu.Label}">
                    <i class="fa fa-fw" [ngClass]="menu.Icon"></i>
                    {{menu.Label }}
                </a>
                <ul *ngIf="menu.Childs && menu.Childs.length > 0 && menu.IsChildVisible" class="np-sub-menu">
                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: menu.Childs }">
                    </ng-container>
                </ul>
            </li>
        </ng-template>
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: items }"></ng-container>
    </ul>
</p-sidebar>