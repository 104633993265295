import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { ErrorComponent } from './components/error/error.component';
import { RouteGuard } from './guards/route.guard';


const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    },
    {
        path: '',
        component: LayoutComponent,
        children: [{
            path: 'dashboard',
            loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
            canActivate: []
        },
        {
            path: 'reporting',
            loadChildren: () => import('./pages/reporting/reporting.module').then(m => m.ReportingModule),
            canActivate: []
        },
        {
            path: 'proposals',
            loadChildren: () => import('./pages/reporting/reporting.module').then(m => m.ReportingModule),
            canActivate: []
        }
        ],
        canActivate: mapToCanActivate([AuthGuard,RouteGuard])
    },
    {
        path: 'error',
        component: ErrorComponent,
        //loadChildren: () => import('src/app/shared/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }