

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { routes } from '../routes';
import { fetchAuthSession } from 'aws-amplify/auth';

@Injectable({
    providedIn: 'root'
})
export class RouteGuard {

    constructor(private amplifyService: AuthenticatorService, private router: Router) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            const user = await fetchAuthSession();
            const payload = user.tokens?.idToken?.payload || {};
            const role = payload['custom:role'];
            const page = route.firstChild;

            if (route.url?.length > 0) {
                const url = route.url[0].path;
                const found = routes.find(el => el.path == url);
                if (found) {
                    const hasRole = found.roles.find(el => el.role == role);
                    if (hasRole) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                } else {
                    this.router.navigate(['/dashboard']);
                    return false;
                }
            }
            return true;
        } catch (e) {
            this.router.navigate(['/login']);
            return false;
        }

    }


}
