
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { fetchAuthSession } from 'aws-amplify/auth';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(private amplifyService: AuthenticatorService, private router: Router) { }

    async canActivate(): Promise<boolean> {
        try {
            const user = await fetchAuthSession();
            if (!user.tokens?.idToken) {
                this.router.navigate(['/login']);
                return false;
            }
            if (!user) {
                this.router.navigate(['/login']);
                return false;
            }
            return true;
        } catch (e) {
            this.router.navigate(['/login']);
            return false;
        }

    }


}
