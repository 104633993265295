import { Component, OnInit } from '@angular/core';
import { RouteStateService } from '../../services/route-state.service';
import { SessionService } from '../../services/session.service';
import { MenuDataService } from '../../services/menu-data.service';
import { ApplicationStateService } from '../../services/application-state.service';
import { CustomMenuItem } from '../../models/menu-item.model';
import { routes } from '../../routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss']
})
export class MenuComponent implements OnInit {

  items!: any[];
  selectedItem!: string;
  visible!: boolean;

  constructor(private routeStateService: RouteStateService,
    private sessionService: SessionService,
    private menuDataService: MenuDataService,
    private applicationStateService: ApplicationStateService,
    private router: Router) { }

  ngOnInit() {
    this.items = this.menuDataService.getMenuList();

    var that = this;
    this.menuDataService.toggleMenuBar.subscribe(function (data: any) {
      if (data && data != null) {
        that.visible = !that.visible;
      }
    });

    if (this.applicationStateService.getIsMobileResolution()) {
      this.visible = false;
    } else {
      this.visible = true;
    }

    var activeMenu = this.sessionService.getItem("active-menu");
    if (activeMenu) {
      this.selectedItem = activeMenu;
    } else {
      this.selectedItem = "Home";
    }
  }

  ngOnDestroy() {
    this.menuDataService.toggleMenuBar.observers.forEach(function (element) { element.complete(); });
  }

  // on menu click event
  onMenuClick(menu: CustomMenuItem) {
 
 
    this.selectedItem = menu.Label;
    this.sessionService.setItem("active-menu", menu.Label);
  //  this.routeStateService.add(menu.Label, menu.RouterLink, null, true);
    this.router.navigate([`${menu.RouterLink}`])
    // hide menu bar after menu click for mobile layout        
    setTimeout(() => {
      if (this.applicationStateService.getIsMobileResolution()) {
        this.visible = false;
      }
    }, 100);
  }

  // toggle sub menu on click
  toggleSubMenu(menu: CustomMenuItem) {
    menu.IsChildVisible = !menu.IsChildVisible;
  }
  toggleMenu() {
    this.menuDataService.toggleMenuBar.next(true);
  }
}