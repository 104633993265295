import { Component, OnInit } from '@angular/core';
import { SessionService } from './services/session.service';
import { ThemeService } from './services/theme.service';
import { LoaderService } from './services/loader.service';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import dayjs from 'dayjs';
import { sessionStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Ng-Prime';
  showLoader!: boolean;
  theme: string;

  constructor(private loaderService: LoaderService,
    private themeService: ThemeService,
    private sessionService: SessionService) {

    var theme = this.sessionService.getItem("selected-theme");
    if (theme != null && theme.length > 0) {
      this.theme = theme;
      this.themeService.selectTheme(theme);
    } else {
      this.theme = "theme-teal";
    }

    const authConfig: ResourcesConfig['Auth'] = {
      Cognito: {
        userPoolId: amplifyconfig.aws_user_pools_id,
        userPoolClientId: amplifyconfig.aws_user_pools_web_client_id
        
      }
    };
    cognitoUserPoolsTokenProvider.setAuthConfig(authConfig);    
    cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
    

    Amplify.configure({ Auth: authConfig, }, { Auth: { tokenProvider: cognitoUserPoolsTokenProvider } });
  }

  ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });

    this.themeService.theme.subscribe((val: string) => {
      this.theme = val;
    });
  }

  ngOnDestroy() {
    this.loaderService.status.observers.forEach(function (element: any) { element.complete(); });
  }
}